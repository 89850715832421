import { render, staticRenderFns } from "./media.vue?vue&type=template&id=8831bc16&scoped=true&"
import script from "./media.vue?vue&type=script&lang=js&"
export * from "./media.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8831bc16",
  null
  
)

export default component.exports