<template>
    <div class="card my-4">
        <div class="card-body">

            <h4 class="card-title">
                <span class="fa fa-paper-plane-o"></span> Leave a Comment:
            </h4>

            <form>

                <div class="form-group my-1">
                    <label style="display:none" for="commentInput"></label>
                    <textarea class="form-control" ref="commentInput" id="commentInput" rows="3" required></textarea>
                </div>

                <div v-if="successful||error" :class="['form-group m-1 p-3', successful ? 'alert-success' : '', error ? 'alert-danger' : '']">
                    <span v-if="successful" class="label label-success">
                        Comment Sent!
                    </span>
                        <span v-if="error" class="label label-danger">
                        Error Encountered!
                    </span>
                    <button type="button" @click="closeAlert()" class="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <button type="submit" @click.prevent="addComment" class="btn btn-primary">
                    <span class="fa fa-reply"></span> Comment
                </button>

            </form>

        </div>
        <ul class="list-group list-group-flush" v-if="comments.length">
            <li class="list-group-item" v-for="comment in comments">
                <div>
                    <span class="fa fa-comment"></span> <strong>{{ comment.user.name }}</strong>: <small class="text-muted">{{ comment.created_at }}</small>
                </div>
                <div class="bg-light">
                    {{ comment.body }}
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            userName: {
                type: String,
                required: true
            },
            postId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                error: false,
                successful: false,
                comments: []
            };
        },

        created() {
            this.fetchComments();

            // Echo.private("comment").listen("CommentSent", e => {
            //     this.comments.push({
            //         user: {name: e.user.name},
            //         body: e.comment.body,
            //     });
            // });
        },

        methods: {
            fetchComments() {
                axios.get("/" + this.postId + "/comments").then(response => {
                    this.comments = response.data;
                });
            },
            addComment() {
                let commentInput = this.$refs.commentInput.value;
                if (commentInput !== "") {
                    axios
                        .post("/" + this.postId + "/comments", { commentInput })
                        .then(response => {
                            if (response.status === 201) {
                                this.comments.push({
                                    user: {name: this.userName},
                                    body: commentInput,
                                    created_at: "Just now"
                                });
                                this.$refs.commentInput.value = "";
                                this.successful = true;
                                this.error = false;
                            }
                        })
                        .catch(error => {
                            if (!_.isEmpty(error.response)) {
                                this.successful = false;
                                this.error = true;
                            }
                        });
                }
            },
            closeAlert() {
                this.successful = false;
                this.error = false;
            }
        }
    };
</script>

<style scoped>

</style>
