<template>
    <div id="posts" class="container">

        <h2>Read Blog Posts</h2>

        <div v-for="post in posts" class="row border my-1 py-2">
            <div class="col-auto my-auto">
                <img v-if="post.image" :src="'/uploads/posts/' + post.image" height="50" alt="Blog Post Image" />
                <img v-if="!post.image" src="http://placekitten.com/125/50" alt="Image Placeholder" />
            </div>
            <div class="col border-left align-self-center">
                <div class="row align-items-center">
                    <div class="col-10 col-sm-8">
                        {{ post.id }} |
                        {{ post.title }}
                    </div>
                    <div class="col-2 col-sm-4">
                        <router-link :to="{ name: 'update', params: { postId : post.id } }">
                            <button type="button" class="p-1 mx-3 float-right btn btn-light">
                                Update
                            </button>
                        </router-link>
                        <button type="button" data-toggle="modal" v-on:focus="$event.target.blur()" :data-target="['#ModalCenter' + post.id]" class="p-1 mx-3 float-right btn btn-danger">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-for="post in posts" class="modal fade" :id="['ModalCenter' + post.id]" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content bg-gradient-dark text-white">
                    <div class="modal-header border-dark">
                        <h5 class="modal-title" id="ModalCenterTitle">Permanently Delete Post</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span class="fa fa-times-circle text-white" aria-hidden="true"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure you want to Permanently Delete Post [{{ post.id }}]?
                        <br>
                        "{{ post.title }}"
                    </div>
                    <div class="modal-footer border-dark">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" @click="deletePost(post.id)" class="btn btn-danger">Delete Post</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-2 text-center">
                <button type="button" @click="navigate(links.first)" :class="['m-3 btn btn-outline-light', !links.prev ? 'disabled' : '']">
                    First
                </button>
            </div>
            <div class="col-2 text-center">
                <button type="button" @click="navigate(links.prev)" :class="['m-3 btn btn-outline-light', !links.prev ? 'disabled' : '']">
                    Previous
                </button>
            </div>
            <div class="col-2 text-center">
                <button type="button" @click="navigate(links.next)" :class="['m-3 btn btn-outline-light', !links.next ? 'disabled' : '']">
                    Next
                </button>
            </div>
            <div class="col-2 text-center">
                <button type="button" @click="navigate(links.last)" :class="['m-3 btn btn-outline-light', !links.next ? 'disabled' : '']">
                    Last
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.getPosts();
        },
        data() {
            return {
                posts: {},
                links: {}
            };
        },
        methods: {
            getPosts(address) {
                axios.get(address ? address : "/api/posts").then(response => {
                    this.posts = response.data.data;
                    this.links = response.data.links;
                    $(this.$el).fadeTo(1000, 1);
                });
            },
            deletePost(id) {
                //console.log("DELETE " + id);
                axios.delete("/api/posts/" + id).then(response => this.getPosts());
                $('#ModalCenter'+id).modal('hide');
                $(this.$el).fadeTo(1000, 0.2);
                //$('#Post'+id).slideUp(1000);
            },
            navigate(address) {
                this.getPosts(address)
            }
        }
    };
</script>

<style scoped>

</style>
