<template>
    <div>
        <div v-if="!toggleDelete" class="btn-group">
            <a v-bind:href="showAction" class="btn btn-sm btn-outline-dark">
                Show
            </a>
            <a v-bind:href="editAction" class="btn btn-sm btn-outline-dark">
                Edit
            </a>
            <button type="button" @click="toggleControls()" class="btn btn-sm btn-outline-danger">
                Delete
            </button>
        </div>
        <div v-if="toggleDelete" class="btn-group">
            <button type="button" @click="deleteMedia()" class="btn btn-sm btn-outline-danger">
                Yes
            </button>
            <button type="button" @click="toggleControls()" class="btn btn-sm btn-outline-dark">
                No
            </button>
            <span class="btn btn-sm btn-dark">
                Delete?
            </span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            toggleDelete: false,
        };
    },
    props: {
        showAction: {
            type: String,
            required: true
        },
        editAction: {
            type: String,
            required: true
        },
        deleteAction: {
            type: String,
            required: true
        },
        csrfToken: {
            type: String,
            required: true
        },
    },
    methods: {
        deleteMedia() {
            let form = document.createElement("form");
            document.body.appendChild(form);
            form.method = "post";
            form.action = this.deleteAction;
            let csrf = document.createElement("input");
            csrf.name = "_token";
            csrf.value = this.csrfToken;
            csrf.type = "hidden";
            form.appendChild(csrf);
            let method = document.createElement("input");
            method.name = "_method";
            method.value = "delete";
            method.type = "hidden";
            form.appendChild(method);
            form.submit();
        },
        toggleControls() {
            this.toggleDelete = !this.toggleDelete;
        },
    },
}
</script>

<style scoped>

</style>
