<template>
    <div class="container">

        <h2>Create New Blog Post</h2>

        <div>
            <div id="preview">
                <img v-if="url" :src="url" alt="Image Preview" />
                <img v-if="!url" src="http://placekitten.com/375/150" alt="Image Placeholder" />
            </div>
        </div>

        <form class="was-validated">

            <div class="form-group my-1">
                <div :class="['custom-file', errors.image ? 'is-invalid' : '']">
                    <input @change="onFileChange" type="file" ref="image" class="custom-file-input" id="image" required>
                    <label class="custom-file-label" for="image">Choose Primary Image for Blog Post...</label>
                </div>
                <div v-if="errors.image" class="invalid-feedback">{{ errors.image[0] }}</div>
            </div>

            <div class="form-group my-1">
                <label style="display:none" for="title"></label>
                <input type="text" ref="title" class="form-control" id="title" placeholder="Blog Subject" required>
                <div v-if="errors.title" class="invalid-feedback">{{ errors.title[0] }}</div>
            </div>

            <div class="form-group my-1">
                <label style="display:none" for="body"></label>
                <textarea class="form-control" ref="body" id="body" placeholder="Blog Post" rows="8" required></textarea>
                <div v-if="errors.body" class="invalid-feedback">{{ errors.body[0] }}</div>
            </div>

            <div v-if="successful||error" :class="['form-group m-1 p-3', successful ? 'alert-success' : '', error ? 'alert-danger' : '']">
                <span v-if="successful" class="label label-success">
                    New Blog Post Published!
                </span>
                <span v-if="error" class="label label-danger">
                    Error Encountered!
                </span>
                <button type="button" @click="closeAlert()" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <button type="submit" @click.prevent="create" class="btn btn-primary block my-1">
                Create Blog Post
            </button>

        </form>

    </div>
</template>

<script>
    export default {
        props: {
            userId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                error: false,
                successful: false,
                errors: [],
                url: null
            };
        },
        methods: {
            create() {
                const formData = new FormData();
                formData.append("image", this.$refs.image.files[0]);
                formData.append("title", this.$refs.title.value);
                formData.append("body", this.$refs.body.value);
                formData.append("user_id", this.userId);

                axios
                    .post("/api/posts", formData)
                    .then(response => {
                        // Created
                        if (response.status === 201) {
                            this.successful = true;
                            this.error = false;
                            this.errors = [];
                        } else {
                            this.successful = false;
                            this.error = true;
                            this.errors = [];
                        }
                    })
                    .catch(error => {
                        if (!_.isEmpty(error.response)) {
                            // Validation Error
                            if (error.response.status === 422) {
                                this.successful = false;
                                this.error = true;
                                this.errors = error.response.data.errors;
                            } else {
                                this.successful = false;
                                this.error = true;
                                this.errors = [];
                            }
                        }
                    });
            },
            onFileChange(e) {
                const file = e.target.files[0];
                if(typeof(file) === "undefined" || file === null) {
                    this.url = "http://placekitten.com/150/150";
                } else {
                    this.url = URL.createObjectURL(file);
                }
            },
            closeAlert() {
                this.successful = false;
                this.error = false;
            }
        }
    };
</script>

<style scoped>
    #preview {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dotted black;
        min-height: 150px;
    }
    #preview img {
        max-width: 100%;
        max-height: 300px;
    }
</style>

