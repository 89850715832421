<template>
    <div class="container-fluid bg-white border rounded p-4 text-center">
        <div class="row" v-if="medias.length">
            <div class="col-lg-3 col-md-4 col-sm-6" v-for="media in medias">
                <div class="card mb-4 shadow-sm">
                    <img :src="('/./' + media.storagepath + media.filename)" :alt="media.name" class="w-100 shadow-sm rounded">
                    <div class="card-body" v-if="isOwner">
                        <div class="row mb-1">
                            <div class="col d-flex justify-content-between">
                                <div></div>
                                <div class="btn-group">
                                    <button type="button" @click="detachMedia(media.media_id)" class="btn btn-sm btn-outline-danger">
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.getMedias();
    },
    props: {
        routeKey: {
            type: String,
            required: true
        },
        isOwner: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            medias: {
                media_id: '',
                storagepath: '',
                name: '',
                filename: ''
            },
        };
    },
    methods: {
        getMedias() {
            axios
                .get("/albums/" + this.routeKey + "/media")
                .then(response => {
                    this.medias = response.data;
                });
        },
        detachMedia(id) {
            axios
                .put("/albums/" + this.routeKey + "/media/detach", {'medias':[id]})
                .then(response => this.getMedias());
        },
    },
};
</script>

<style scoped>

</style>
