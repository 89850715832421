<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-auto p-4" style="border-right: 2px solid #161e23;">
                <nav class="nav flex-column">
                    <div class="nav-link p-0">
                        <a href="/admin/dashboard" class="text-white" style="font-weight: 600;">
                            Admin Dashboard
                        </a>
                        &nbsp; ||  &nbsp;
                        <a href="/" class="text-white" style="font-weight: 600;">
                            LANDING
                        </a>
                    </div>
                    <div class="nav-link p-0">
                        <hr>
                    </div>
                    <router-link :to="{ name: 'create', params: { userId } }" :class="['nav-link p-0 text-white font-weight-bold text-decoration-none']">
                        NEW POST
                    </router-link>
                </nav>
            </div>
            <div class="col p-4" style="border-left: 2px solid #2a3843;">
                <div class="pb-3" style="border-bottom: 1px solid #2a3843;">
                    <h6 class="d-inline" style="color: #d3d3d3;">
                        Welcome, {{ userName }}
                    </h6>
                    <p @click="logout" class="float-right mr-3" style="cursor: pointer; color: #d3d3d3;">
                        Logout
                    </p>
                </div>
                <div class="pt-4">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            userId: {
                type: String,
                required: true
            },
            userName: {
                type: String,
                required: true
            }
        },
        data() {
            return {};
        },
        methods: {
            logout() {
                axios.post("/logout").then(() => {
                    window.location = "/";
                });
            }
        }
    };
</script>

<style scoped>

</style>
